<template>
  <div class="k-9sptci" v-loading="loading">
    <el-form
      :inline="true"
      class="k-9sptci-header"
      :model="searchForm"
      ref="searchForm"
    >
      <el-form-item label="UID" prop="id">
        <el-input placeholder="UID" v-model="searchForm.id"></el-input>
      </el-form-item>
      <el-form-item label="主播名称" prop="name">
        <el-input placeholder="主播名称" v-model="searchForm.name"></el-input>
      </el-form-item>

      <el-form-item label="状态" prop="status">
        <el-select v-model="searchForm.status" clearable placeholder="请选择">
          <el-option value="-1" label="全部"></el-option>
          <el-option value="0" label="编辑中"></el-option>
          <el-option value="1" label="线上"></el-option>
          <el-option value="2" label="回收站"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="success" @click="searchClick">查询</el-button>
      </el-form-item>
      <perContainer :perm="`armory:${type}:save`">
        <el-form-item>
          <el-button type="primary" @click="add">新增</el-button>
        </el-form-item>
      </perContainer>
      <perContainer :perm="`armory:${type}:saverule`">
        <el-form-item>
          <el-button type="warning" @click="ruleVisible = true"
            >编辑规则</el-button
          >
        </el-form-item>
      </perContainer>
      <perContainer :perm="`armory:${type}:publishrank`">
        <el-form-item>
          <el-button type="warning" @click="publish">发布</el-button>
        </el-form-item>
      </perContainer>
      <perContainer :perm="`armory:${type}:importinfo`">
        <importButton :type="type" :sub_type="'info'"></importButton>
      </perContainer>
      <perContainer :perm="`armory:${type}:importinfo`">
        <importHistoryButton
          :type="type"
          :sub_type="'info'"
        ></importHistoryButton>
      </perContainer>
      <!--      <el-form-item>-->
      <!--        <el-button @click="handleReset()">重置</el-button>-->
      <!--      </el-form-item>-->
    </el-form>
    <el-table :data="dataTable" style="margin-top:20px" border>
      <el-table-column width="55">
        <template slot="header" slot-scope="scope">
          <el-checkbox
            @click.native.stop="handleSelectAll($event)"
            v-model="selectAll"
            v-if="canPublishDatas.length > 0"
          ></el-checkbox>
        </template>
        <template slot-scope="scope">
          <el-checkbox
            @click.native.stop="handleSelectionChange(scope.row, $event)"
            v-model="scope.row.checked"
            v-if="scope.row.status != 2"
          ></el-checkbox>
        </template>
      </el-table-column>
      <template v-for="item in dataList">
        <el-table-column
          v-if="item.prop == 'avatar'"
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
          <template slot-scope="scope">
            <img
              v-if="scope.row.avatar && scope.row.avatar.length > 0"
              style="border-radius: 15px; width: 30px; height: 30px"
              :src="scope.row.avatar"
            />
            <div v-else style="color: #777777; width: 30px; text-align: center">
              暂无
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-else
          :key="item._id"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
          :formatter="item.formatter"
        >
        </el-table-column>
      </template>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <perButton
            :perm="`armory:anchor:save`"
            @click.native.stop="handleEdit(scope.row)"
            >编辑星主播</perButton
          >
          <popconfirmButton
            v-if="scope.row.status != 2"
            :perm="`armory:anchor:save`"
            content="是否确认删除？"
            text="删除"
            @onConfirm="handleDelete(scope.row)"
            style="margin-left: 10px"
          ></popconfirmButton>
          <popconfirmButton
            v-else
            :perm="`armory:anchor:save`"
            content="是否确认恢复？"
            text="恢复"
            @onConfirm="handleRecovery(scope.row)"
            style="margin-left: 10px"
          ></popconfirmButton>
        </template>
      </el-table-column>
    </el-table>
    <div class="k-9sptci-footer">
      <el-pagination
        @current-change="handleQuery"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageSize"
        :current-page.sync="pager.pageNum"
        layout="sizes, prev, pager, next"
        :total="pager.total"
      >
      </el-pagination>
    </div>

    <ruleManager
      v-if="ruleVisible"
      :type="type"
      @onClose="ruleVisible = false"
    ></ruleManager>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import perButton from "@/components/perm/perButton";
import ruleManager from "../components/ruleManager";
import importButton from "../components/importButton";
import importHistoryButton from "../components/importHistoryButton";
export default {
  name: "anchor_list",
  components: {
    perButton,
    ruleManager,
    importButton,
    importHistoryButton,
  },
  props: {},
  data() {
    let self = this;
    return {
      ids: [],
      type: "anchor",
      loading: false,
      importStatus: -1,
      pageSize: 10,
      pager: {
        total: 0,
        pageNum: 1,
      },
      searchForm: {
        id: "",
        name: "",
        status: "",
      },
      dataTable: [],
      dataList: [
        {
          prop: "uid",
          label: "UID",
          width: 100,
        },
        {
          prop: "name",
          label: "主播名称",
          width: 300,
        },
        {
          prop: "avatar",
          label: "头像",
          width: 200,
        },
        {
          prop: "platform",
          label: "平台",
          width: 200,
        },
        {
          prop: "room",
          label: "平台房间号",
          width: 200,
        },
        {
          prop: "sort",
          label: "排名",
          width: 100,
        },
        {
          prop: "status",
          label: "状态",
          width: 80,
          formatter: (row) => {
            switch (row.status) {
              case 0:
                return "编辑中";
              case 1:
                return "线上";
              case 2:
                return "回收站";
              default:
                return "";
            }
          },
        },
      ],
      ruleVisible: false,
      parentTableData: [],
    };
  },
  computed: {
    canPublishDatas: function() {
      let tables = this.dataTable.filter((r) => r.status != 2);
      return tables;
    },
    selectAll: function() {
      let tables = this.canPublishDatas;
      let parentTableData = this.parentTableData;
      if (
        tables.length <= 0 ||
        tables.find((r) => parentTableData.indexOf(r._id) < 0)
      ) {
        return false;
      }

      return true;
    },
  },
  methods: {
    handleSelectAll($event) {
      if ($event.target.tagName !== "INPUT") return;
      if (this.selectAll) {
        this.parentTableData = [];
        this.dataTable.forEach((r) => {
          r.checked = false;
        });
      } else {
        this.parentTableData = [];
        this.dataTable
          .filter((r) => r.status != 2)
          .forEach((r) => {
            r.checked = true;
            this.parentTableData.push(r._id);
          });
      }
    },
    handleSelectionChange(row, $event) {
      if ($event.target.tagName !== "INPUT") return;
      let index = this.parentTableData.indexOf(row._id);
      if (index >= 0) {
        this.parentTableData.splice(index, 1);
      } else {
        this.parentTableData.push(row._id);
      }
    },
    add() {
      this.removeTabByPath("/armory/anchor/add");
      this.removeTabByPath("/armory/anchor/edit");
      this.$router.push({
        path: "/armory/anchor/add",
      });
    },
    async publish() {
      if (!this.parentTableData.length) {
        this.$message.error("请选择要发布的数据");
        return;
      }

      this.loading = true;
      let { errorCode } = await this.$http.armory.publishRank(
        this.type,
        this.parentTableData
      );
      if (errorCode == "0000") {
        this.$message({
          type: "success",
          message: "发布成功",
        });
      }

      this.loading = false;
      this.handleQuery();
    },
    async handleEdit(row) {
      this.removeTabByPath("/armory/anchor/add");
      this.removeTabByPath("/armory/anchor/edit");
      this.$router.push({
        path: "/armory/anchor/edit",
        query: { _id: row._id },
      });
    },
    async handleDelete(row) {
      this.loading = true;
      let { data, errorCode } = await this.$http.armory.deleteInfo(
        row._id,
        this.type
      );
      this.loading = false;

      if (errorCode != "0000") {
        return;
      }

      this.$message({
        type: "success",
        message: "删除成功",
      });

      row.status = data.status;
      if (row.status == 2) {
        row.checked = false;
        let index = this.parentTableData.indexOf(row._id);
        if (index >= 0) {
          this.parentTableData.splice(index, 1);
        }
      }
    },
    async handleRecovery(row) {
      this.loading = true;
      let { data, errorCode } = await this.$http.armory.recovery(
        row._id,
        this.type
      );
      this.loading = false;

      if (errorCode != "0000") {
        return;
      }

      this.$message({
        type: "success",
        message: "恢复成功",
      });

      row.status = data.status;
      if (row.status == 2) {
        row.checked = false;
        let index = this.parentTableData.indexOf(row._id);
        if (index >= 0) {
          this.parentTableData.splice(index, 1);
        }
      }
    },
    searchClick() {
      this.$refs.searchForm.validate((v) => {});
      this.pager.pageNum = 1;
      this.handleQuery();
    },
    async handleSizeChange(val) {
      this.pageSize = val;
      this.pager.pageNum = 1;
      this.pager.total = 0;
      this.handleQuery();
    },
    async handleQuery() {
      const { pageNum } = this.pager;
      const { data, errorCode } = await this.$http.armory.list(this.type, {
        ...this.searchForm,
        pageNum,
        pageSize: this.pageSize,
      });
      if (errorCode != "0000") return;
      this.parentTableData = [];
      data.data.forEach((r) => {
        r.checked = this.parentTableData.includes(r._id);
      });
      this.dataTable = data.data;
      this.pager = data.pager;
    },
    onAnchorChanged(data) {
      let current = this.dataTable.find((r) => r._id == data._id);
      if (current) {
        current.uid = data.uid;
        current.name = data.name;
        current.avatar = data.avatar;
        current.sort = data.sort;
        current.platform = data.platform;
        current.room = data.room;
        current.status = data.status;
        if (current.status == 2) {
          current.checked = false;
          let index = this.parentTableData.indexOf(current._id);
          if (index >= 0) {
            this.parentTableData.splice(index, 1);
          }
        }
      } else {
        this.pager.pageNum = 1;
        this.handleQuery();
      }
    },
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  async created() {
    this.handleQuery();
    this.$bus.$off("onAnchorChanged", this.onAnchorChanged);
    this.$bus.$on("onAnchorChanged", this.onAnchorChanged);
  },
  destroyed() {
    this.$bus.$off("onAnchorChanged", this.onAnchorChanged);
  },
};
</script>
<style lang="less">
.k-9sptci-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}
</style>
